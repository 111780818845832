<template>
    <span>{{val}}</span>
</template>

<script>
export default {
  name: 'money-format',
  props: {
    money: {
      type: Number
    }
  },
  computed: {
    val () {
      return this.moneyFormat(this.money)
    }
  },
  methods: {
    moneyFormat (num) {
      num = parseFloat(num).toFixed(2).toString().split('.')
      num[0] = num[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)', 'ig'), '$1,')
      return num.join('.')
    }
  }
}
</script>
