// 卖方
export default [
  // 订单列表
  {
    path: '/center/seller/seedorders/list',
    component: () => import('@/views/center/seller/seedorders/list.vue')
  },
  // 添加
  {
    path: '/center/seller/seedorders/add',
    component: () => import('@/views/center/seller/seedorders/add.vue')
  },
  // 定向详情
  {
    path: '/center/seller/seedorders/detail/:id',
    component: () => import('@/views/center/seller/seedorders/detail.vue')
  }
]
