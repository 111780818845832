<template>
  <div class="footer-module">
    <div class="service-box">
      <div class="w f-list-infro clearfix">
        <ul>
          <li :key="i" v-for="(item,i) in fList">
            <img :src="item.img" alt />
            <div>
              <h2 class="f14 m-t-10">{{item.title}}</h2>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <div class="service-box2 w clearfix">
<!--      <div class="footer-left">-->
<!--        <ul class="clearfix">-->
<!--          <li :key="parent.code" v-for="(parent) in helpList">-->
<!--            <h6>{{parent.name}}</h6>-->
<!--            <template v-for="(child, i) in (parent.children || [])">-->
<!--              <a :key="child.code" @click="toDetail(child)" href="javascript:;" v-if="i < 6">{{child.name}}</a>-->
<!--            </template>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->

<!--      <div class="footer-right">-->
<!--        <div class="f">-->
<!--          <div class="ser-box">-->
<!--            <div>-->
<!--              <p>微信公众号</p>-->
<!--              <img alt src="../../../assets/img/footer-wx.png" />-->

<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <!-- 诚信说明 -->
    <div class="service-box3">
      <div class="wrap">
        <p class="m-b-10">
          <a :href="linkUrl" target="_blank">禾众网</a>
          <a href="http://www.miibeian.gov.cn/" target="_blank">copyright© 2022 禾众网 - 全国首创种业交易平台 福建省建宁县种业科技中心 浙ICP备19039988号-2</a>
        </p>
        <div class="footer-img">
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img3.jpg" width="124" />
          </a>
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img4.jpg" width="124" />
          </a>
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img5.jpg" width="124" />
          </a>
          <a href="javascript:;">
            <img height="46" src="//a1.gystatic.com/v6/images/footer/foot_img7.jpg" width="124" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import navigationApi from '../../../api/navigationApi'

export default {
  inject: [ 'reload' ],
  data () {
    return {
      // linkUrl: this.$defaultLinkOrigin + '/',
      linkUrl: this.$defaultLinkOrigin,
      fList: [
        {
          title: '免费找货',
          img: require('@/assets/img/footer/t-icon1.png')
        },
        {
          title: '仓库直供',
          img: require('@/assets/img/footer/t-icon2.png')
        },
        {
          title: '规格齐全',
          img: require('@/assets/img/footer/t-icon3.png')
        },
        {
          title: '一体化服务',
          img: require('@/assets/img/footer/t-icon4.png')
        },
        {
          title: '供应链服务',
          img: require('@/assets/img/footer/t-icon5.png')
        }
      ],
      helpList: []
    }
  },
  created () {
    this.queryHelpList()
  },
  methods: {
    queryHelpList () {
      navigationApi.tree({
        classify: 'SERVICE_ITEM'
      }).then(data => {
        sessionStorage.setItem('helpMenuList', JSON.stringify(data))
        // this.helpList = data;
        this.helpList = data.length < 4 ? data : data.splice(0, 4)
      }).catch(error => {
        console.error('查询底部列表信息遇到异常:', error)
      })
    },
    toDetail ({ resource } = {}) {
      if (resource.target && resource.target == '_BLANK') {
        var tempwindow = window.open('_blank')
        tempwindow.location = resource.url
      } else {
        const url = resource.singlePage ? `/help/helpDetail/${resource.code}` : `/help/helpList/${resource.code}`
        if (window.location.pathname == url) {
          this.reload()
        } else {
          this.$router.push({
            path: url
          })
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.footer-module {
  .service-box {
    min-width: 1200px;
    background: #fff;
    padding: 25px 0 0 0;
    .wrap {
      border: solid 1px #c8a977;
      font-size: 14px;
      border-radius: 6px;
      width: 1200px;
      overflow: hidden;
      color: #c8a977;
      margin: 0 auto;
      li {
        float: left;
        text-align: center;
        width: 33.33%;
        padding: 10px 0;
      }
      em {
        font-weight: bold;
      }
    }
    .f-list-infro {
      li {
        text-align: center;
        width: 20%;
        float: left;
        padding-bottom: 20px;
        font-size: 14px;
        display: flex;
        img {
          margin-right: 10px;
          width: 40px;
        }
        h2 {
          font-size: 16px;
          color: #666;
        }
      }
    }
  }
  .service-box2 {
    background: #fff;
    position: relative;
    min-height: 165px;
    width: 1200px;
    margin: 10px auto 0;
    min-width: 1200px;
    ul {
      h6 {
        color: #666;
        font-size: 14px;
        font-weight: bold;
        display: block;
        margin-bottom: 14px;
      }
      li {
        width: 243px;
        height: auto;
        margin-bottom: 20px;
        float: left;
      }
      a {
        display: block;
        color: #999;
        font-size: 13px;
        line-height: 2;
        cursor: pointer;
      }
    }
    .line {
      width: 2%;
      display: flex;
      align-items: center;
      span {
        background: #e9e9e9;
        width: 1px;
        height: 100px;
        display: inline-block;
      }
    }
    .footer-left {
      /*width: 70%;*/
      float: left;
    }
    .footer-right {
      width: 30%;
      position: absolute;
      top: 0;
      right: 0;
      .ser-box {
        width: 98%;
        position: relative;
        min-height: 190px;
        display: flex;
        justify-content: flex-end;
        img{
          width: 100px;
        }
        div:first-child {
          margin-right: 25px;
        }
        > img {
          display: inline-block;
        }
        p {
          text-align: center;
        }
      }
      .phone {
        font-weight: bold;
        display: block;
        margin-bottom: 8px;
      }
      .ask {
        width: 150px;
        padding: 8px 20px;
        border-radius: 1px;
      }
      .service-time {
        margin-bottom: 5px;
      }
    }
  }

  .service-box2 ul li.ewm {
    width: 340px;
    border: none;
    padding-left: 50px;
  }
  .service-box2 ul .ewm-l {
    width: 160px;
    float: left;
    display: inline;
    span {
      font-size: 16px;
      color: $color-primary-base;
      font-weight: bold;
      margin-bottom: 10px;
    }
    a {
      width: 130px;
      height: 28px;
      text-align: center;
      line-height: 28px;
      border: 1px solid $color-primary-base;
      background-color: $color-primary-base;
      color: #fff;
      margin: 10px 0;
    }
  }
  .spe-bg {
    height: 35px;
    background: $color-primary-base;
  }
  .service-box3 {
    .wrap {
      width: 1200px;
      margin: 0 auto;
      min-width: 1200px;
      padding-top: 15px;
      position: relative;
      border-top: 1px solid #e7e7e7;
      .footer-img {
        text-align: center;
        position: absolute;
        right: 0;
        top:16px;
      }
      .footer-img img {
        width: 50px;
        height: auto;
      }
      p {
        font-size: 13px;
        line-height: 24px;
        color: #999;
      }
      a {
        line-height: 24px;
        padding: 0 10px;
        color: #999;
      }
    }
  }
}
</style>
