import storage from './Storage';

/**
 * 购物车对象
 */
export default {
  /**
   * 获取购物车数量
   *
   * @returns {int}
   */
  get length() {
    return this.data.length;
  },
  /**
   * 设置购物车商品数量
   *
   * @param {Number} len
   */
  set length(len) {
    let sc = [];
    for (let i = 0; i < len; i++) {
      sc.push({});
    }
    this.data = sc;
  },
  /**
   * 添加商品到购物车
   *
   * @param goods
   */
  add(goods) {
    let data = this.data;
    //TODO 判断商品是否存在

    data.push(goods);

    this.data = data;
  },
  /**
   * 减少商品数量
   * @param goods
   */
  sub(goods) {
    let data = this.data;
    //TODO 处理商品数量减少逻辑
    this.data = data;
  },
  /**
   * 获取购物车数据
   *
   * @returns {[]}
   */
  get data() {
    return storage.session.get('SHOP_CAR') || [];
  },
  /**
   * 设置购物车数据
   *
   * @param list
   */
  set data(list) {
    storage.session.set("SHOP_CAR", list);
  }
}
