import Vue from 'vue'
import VueRouter from 'vue-router'
import centerRouter from './center'
import { local, session } from 'me-view/lib/script/storage'
import LoginUser from '../assets/script/LoginUser'
import Storage from '../assets/script/Storage'
import { Message } from 'element-ui'
import { http } from 'security-view-deal'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      component: () => import('@/views/index/index.vue')
      // component: resolve => require([ '@/views/index/index' ], resolve)

    },
    {
      path: '/noAccess',
      component: () => import('@/views/account/noAccess.vue')
    },
    {
      path: '/login',
      component: () => import('@/views/account/login.vue')
    },
    {
      path: '/register',
      component: () => import('@/views/account/register.vue')
    },
    {
      path: '/forgetPassword',
      component: () => import('@/views/account/forgetPassword.vue')
    },
    // 法大大回跳地址
    {
      path: '/sign/auth',
      component: () => import('@/views/sign/auth.vue')
    },
    // 法大大回跳地址
    {
      path: '/fund/payok',
      component: () => import('@/views/sign/payok.vue')
    },
    // 移动端在线登记
    {
      path: '/mobile/signin',
      component: () => import('@/views/mobile/signin.vue')
    },
    // 移动端在线登记
    {
      path: '/mobile/signintwo',
      component: () => import('@/views/mobile/signintwo.vue')
    },
    // 移动端资讯列表
    {
      path: '/mobile/newslist',
      component: () => import('@/views/mobile/newslist.vue')
    },
    // 移动端资讯详情
    {
      path: '/mobile/newsDetail',
      component: () => import('@/views/mobile/newsDetail.vue')
    },
    // 求购信息列表
    {
      path: '/purchase/list',
      component: () => import('@/views/purchase/list.vue')
    },
    {
      path: '/purchase/ricelist',
      component: () => import('@/views/purchase/ricelist.vue')
    },
    // 发布求购信息
    {
      path: '/purchase/publish',
      component: () => import('@/views/purchase/publish.vue')
    },
    // 米制品订单发布
    {
      path: '/purchase/ricepublish',
      component: () => import('@/views/purchase/ricepublish.vue')
    },
    // 普通商品列表
    {
      path: '/goods/list',
      component: () => import('@/views/goods/list.vue')
    },
    // 饲料专区列表
    {
      path: '/goods/fodderlist',
      component: () => import('@/views/goods/fodderList.vue')
    },
    {
      path: '/goods/specialList',
      component: () => import('@/views/goods/specialList.vue')
    },
    // 商品详情
    {
      path: '/goods/detail',
      component: () => import('@/views/goods/goods.vue')
    },
    {
      path: '/goods/category',
      component: () => import('@/views/goods/category.vue')
    },
    {
      path: '/goods/compare',
      component: () => import('@/components/business/goods-compare.vue')
    },
    // 商品详情
    {
      path: '/shopping',
      component: () => import('@/views/shopping/shopping.vue')
    },
    // 确认订单
    {
      path: '/order/comfirmOrder',
      component: () => import('@/views/order/comfirmOrder.vue')
    },
    // 商铺
    {
      path: '/shop/list',
      component: () => import('@/views/shop/list.vue')
    },
    {
      path: '/shop/detail/:id',
      component: () => import('@/views/shop/detail.vue')
    },

    // 页面demo示例
    {
      path: '/testdemo',
      component: () => import('@/views/testDemo/testTable.vue')
    },

    // 帮助中心
    {
      path: '/help',
      component: () => import('@/views/help/index.vue'),
      children: [
        {
          path: '/help/helpDetail/:code',
          component: () => import('@/views/help/helpDetail.vue')
        },
        {
          path: '/help/helpList/:code',
          component: () => import('@/views/help/helpList.vue')
        }
      ]
    },
    ...centerRouter
  ]
})

router.beforeEach(async (to, from, next) => {
  refreshLogin()

  if (to.path === '/center/home') {
    // console.debug('程序开始清空页面及按钮权限信息...');
    Storage.session.remove('LOGIN_USER_PAGE')
    Storage.session.remove('LOGIN_USER_BUTTON')
  }

  if (to.meta.authority === undefined || to.meta.authority === null || to.meta.authority === false) {
    return next(true)
  }
  if (LoginUser.isLogin()) {
    let loginUserPage = Storage.session.get('LOGIN_USER_PAGE')
    // 本地没有用户权限信息时，重新加载
    if (loginUserPage === undefined || loginUserPage === null) {
      const res = await http.get('/member/right/userAllRight', {
        systemCode: 'PORTAL',
        verifiable: false
      })
      // console.debug('本次获取到的用户权限信息为:', res);
      const loginUserButton = {}
      loginUserPage = {}
      for (const o in res) {
        switch (res[o]) {
          case 'MENU_TYPE-PAGE':
            loginUserPage[o] = res[o]
            break
          case 'MENU_TYPE-BUTTON':
            loginUserButton[o] = res[o]
            break
          default:break
        }
      }
      Storage.session.set('LOGIN_USER_PAGE', loginUserPage)
      Storage.session.set('LOGIN_USER_BUTTON', loginUserButton)
    }

    // 登录后判断该地址是否受权限保护
    const protectedPage = Storage.session.get('PROTECTED_PAGE') || {}
    if (!Reflect.has(protectedPage, to.path)) {
      return next(true)
    }
    if (Reflect.has(loginUserPage, to.path)) {
      return next(true)
    }
    // if (to.path === '/center/store/apply' || to.path === '/center/info') {
    //   return next(true)
    // }
    console.debug('页面路由地址为:', to.path)
    // Message({
    //   type: 'error',
    //   message: "无权访问该页面!"
    // });
    // return next(false);
    return next('/noAccess')
  }
  console.debug('本次访问地址鉴权未通过:', to.path)
  if (to.path === '/login') {
    Message({
      type: 'error',
      message: '登录地址路由鉴权方式配置错误!'
    })
  }

  next('/login')
})

/**
 * 刷新登录状态
 */
function refreshLogin () {
  const l_token = local.get('authorization')
  if (l_token === undefined || l_token === null || l_token === '') {
    return
  }
  // console.debug('本次获取到的local中的token信息为', l_token);
  const s_token = session.get('authorization')
  if (s_token === null || s_token === '' || undefined === s_token) {
    // console.debug('session中没有token信息,程序开始将local中的token写入到session中...')
    session.set('authorization', l_token)
    return
  }
  // console.debug('本次获取到的session中的token信息为:', s_token);
  if (s_token === l_token) {
    return
  }
  // console.debug('session中的token信息与local中的token信息不一致，程序开始保存token信息');
  local.set('authorization', s_token)
}

// 进入页面默认回到顶部
router.afterEach(() => {
  window.scrollTo(0, 0)
})
export default router
