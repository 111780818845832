<template>
  <div id="app">
    <layout v-if="isRouterAlive"></layout>
  </div>
</template>

<script>
import 'babel-polyfill'
import layout from '@/views/layout/layout.vue'
import { http } from 'security-view-deal'
import Storage from './assets/script/Storage'
export default {
  name: 'app',
  components: {
    layout
  },
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlive: true
    }
  },
  created () {
    this.loadDictList()
  },
  methods: {
    reload () {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    loadDictList () {
      http.get('/security/dict/item/list', {}).then(data => {
        Storage.session.set('DATA_DICT', data)
        // eslint-disable-next-line handle-callback-err
      }).catch(error => {
        Storage.session.set('DATA_DICT', [])
      })
    }
  }
}
</script>
