<template>
  <div>
    <div class="header-bg">
      <header :style="{'background':isBgGrey ? '#f6f6f6':'#fff'}" class="header-module">
        <!-- 顶部导航 -->
        <div :class="['header-box', 'w', isCenter?'center':'']">
          <ul class="header-left">
            <li class="header-item">
              <a @click="to('/')" class="right10" href="javascript:;">欢迎来到禾众网平台</a>
              <a @click="to('/center/home')" style="cursor: pointer;" v-if="loginFlag">您好，{{LoginUser.name}},{{LoginUser.mobile}},{{LoginUser.detail.scompanyname}}</a>
              <a @click="to('/login')" class="login-name" href="javascript:;" v-if="!loginFlag">您好，请登录</a>
              <a @click="to('/register')" href="javascript:;" v-if="!loginFlag">免费注册</a>
            </li>
          </ul>
          <ul class="header-right">
            <li class="header-item 0" v-if="loginFlag">
<!--              <a @click="to('/center/member/messageList')" class="icon-text" href="javascript:;">-->
              <a @click="showMessageListFn()" class="icon-text" href="javascript:;">
                <span>
                  消息
                  <i>（{{$store.getters.getReadMessage}}）</i>
                </span>
              </a>
            </li>
            <li class="shu" v-if="loginFlag"></li>
            <li class="header-item 0" v-if="loginFlag">
              <a @click="to('/shopping')" class="icon-text" href="javascript:;">
                <span>
                  购物车
                  <i>（{{ShopCar.length}}）</i>
                </span>
              </a>
            </li>
            <li class="shu" v-if="loginFlag"></li>
            <li class="header-item">
              <a @click="to('/help/helpDetail/6bc1659d208143d68dce572e637a58ea')" href="javascript:;">帮助中心</a>
            </li>
            <li class="shu"></li>
            <li class="header-item 0">
              <a @click="to('/center/home')" href="javascript:;">会员中心</a>
            </li>
<!--            <li class="shu"></li>-->
<!--            <li class="header-item 0">-->
<!--              下载App-->
<!--            </li>-->

            <li class="shu" v-if="loginFlag"></li>
            <li class="header-item 0" v-if="loginFlag">
              <a @click="logOut" class="icon-text" href="javascript:;">
                <span>退出</span>
              </a>
            </li>
          </ul>
        </div>
      </header>

      <!--  logo和搜索 -->
      <div v-if="!setHeader()">
        <div class="top-tool-bg" v-if="!setCenterHeader()">
          <div class="w logo-box" style="display: flex;position: relative">
            <div @click="to('/')" class="logo-bg" style="cursor: pointer">
              <img :src="logo" alt />
            </div>
            <div class="search-box" style="margin-left: 180px">
              <input @keyup.enter="handleSearch" class="s-input" placeholder="请搜索感兴趣的商品" style="font-size: 13px;" type="text" v-model="search" />
              <input @click="handleSearch" class="s-button" type="button" value="搜索" />
            </div>

            <div class="header-tool-box">
              <!-- 登录状态 -->
<!--              <template v-if="!loginFlag">-->
<!--                <a @click="to('/login')" class="top-btn top-btn-login" href="javascript:;">-->
<!--                  <span>登录 | 注册</span>-->
<!--                </a>-->
<!--              </template>-->
              <a @click="to('/shopping')" class="top-btn" href="javascript:;">
                <el-badge :value="ShopCar.length > 0 ? ShopCar.length : ''">
                  <i class="iconfont icon-gouwuche"></i> 购物车
                </el-badge>
              </a>

            </div>
          </div>

        </div>
        <!-- 工具导航 -->
        <div class="nav-bg">
          <div class="nav-img"></div>
        </div>
        <div :class="!setCenterHeader()?'nav-module-else':''" class="nav-module">
          <div :class="['nav-bg', 'w', isCenter?'center':'']">
            <!--会员中心顶部导航栏-->
            <div class="focus-a" v-if="setCenterHeader()" style="padding-left: 42px">
              <div class="center-logo" style="color: #fff;font-size: 16px;text-align: left;cursor: pointer">
                <img src="@/assets/img/logo-white.png" @click="goHome" style="width: 105px;vertical-align: middle;margin-top: -5px;" />
              </div>
            </div>
            <div
              @mouseenter="mouseenterMenu"
              @mouseleave="mouseleaveMenu"
              @mouseover="mouseenterMenu"
              class="focus-a"
              v-if="!setCenterHeader()">
              <div class="fouc-img1">
                <div class="fouc-font">
                  <div class="f-s-b">
                    <a class="f-a-i" href="javascript:;">
                      <i class="iconfont icon-menu f18"></i>产品分类
                    </a>
                    <i class="iconfont icon-arrow-down f18"></i>
                  </div>
                </div>
              </div>

              <div class="dd-inner" v-show="navShow">
                <div class="dd-innerbg">
                  <div class="dd-inner-box">
                    <div :key="parentIndex" class="font-item" v-for="(parentCategory, parentIndex) in goodsCategoryList">
                      <div class="item fore1">
                        <h3>
                          <a @click="toGoodsListPage(parentCategory.code)" href="javascript:;">{{parentCategory.name}}</a>
                        </h3>
                        <!-- 此处去掉links显示有bug，先隐藏二级links-->
                        <p v-show="false">
                          <a
                            :key="link.sguid"
                            @click="toGoodsListPage(parentCategory.code, link.sguid)"
                            style="cursor:pointer;"
                            v-for="(link, linkIndex) in (parentCategory.links || []).filter((v, i) => i < 3)"
                          >
                            {{link.sname}}
                            <template v-if="linkIndex !== parentCategory.links.length - 1">|</template>
                          </a>
                        </p>
                        <!-- 显示二级菜单 -->
                         <p>
                          <span :key="linkIndex" v-for="(link, linkIndex) in ( parentCategory.children || [])">
                            <a @click="toGoodsListPage(link.code)" style="cursor:pointer;" v-if="linkIndex <3">
                              {{link.name}}
                              <template v-if="linkIndex !==  parentCategory.children.length - 1">|</template>
                            </a>
                          </span>
                        </p>
<!--                        <i>&gt;</i>-->
                        <i class="el-icon-arrow-right"></i>
                      </div>
                      <div class="font-item1">
                        <ul class="font-lefty">
                          <dl :key="childIndex" class="fore1 clearfix" v-for="(childCategory, childIndex) in parentCategory.children">
                            <dt>
                              <a @click="toGoodsListPage(childCategory.code)">
                                {{childCategory.name}}
                                <i>&gt;</i>
                              </a>
                            </dt>
                            <dd>
                              <a
                                :key="thirdIndex"
                                @click="toGoodsListPage(childCategory.code, commodity.sguid)"
                                v-for="(commodity, thirdIndex) in childCategory.commodityList"
                              >{{commodity.sname}}</a>
                            </dd>
                          </dl>
                          <div style="height:1px;border-top:solid rgb(214 211 211) 1px;"></div>
                          <dl class="fore1 clearfix" v-for="(am, key) in parentCategory.attrMap">
                            <dt>
                                {{am[0]}}
                                <i>&gt;</i>
                            </dt>

                            <dd>
                              <a @click="toGoodsListAttributePage(parentCategory.code,am[0],am[0],amv.label,amv.value)"
                                v-for="(amv, amvIndex) in am[1]"
                              >{{amv.label}}</a>
                            </dd>

                          </dl>

                        </ul>

                        <div class="brand-box">
                          <div class="brand-box-head">
                            <i class="icon icon-guanli5"></i>
                            <span></span>
                          </div>
                          <div class="brand-box-body"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 工具导航列表 -->
            <div class="focus-b">
              <ul class="nav-box" v-if="setCenterHeader()">
                <li :key="index" v-for="(item,index) in navLists">
                  <a
                    :class="['f16',handleNav(item.path) ? 'active':$store.getters.getRoleValue==item.name?'active':'']"
                    :title="item.name"
                    @click="to(item.path,item.query)"
                    href="javascript:;"
                  >{{item.name}}</a>
                </li>
              </ul>
              <ul class="nav-box" v-else>
                <!-- <li :key="index" v-for="(item,index) in navLists" v-if="setCenterHeader()">
                  <a
                    :class="['f16',handleNav(item.path) ? 'active':'']"
                    :title="item.name"
                    @click="to(item.path,item.query)"
                    href="javascript:;"
                  >{{item.name}}</a>
                </li> -->
               <!-- <li v-if="setCenterHeader()">
                 <a  class="active" href="/center/home" >我是买家</a>
                 <a  class="active" href="/center/homes" >我是卖家</a>
               </li> -->
                <li :key="index" v-for="(item,index) in navList" >
                  <a
                    :class="['f16',handleNav(item.path) ? 'active':'']"
                    :title="item.name"
                    @click="goto(item)"
                    href="javascript:;"
                  >{{item.name}}
                    <img class="icon" v-if="item.name === '禾众网商城'" src="@/assets/img/icon-hot.png" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/img/logo1.png'
import LoginUser from '@/assets/script/LoginUser'
import ShopCar from '../../../assets/script/ShopCar'
import { http } from 'security-view-deal'
import shopApi from '../../../api/shopApi'

export default {
  data () {
    // 以下页面——不显示顶部公共搜索和logo
    this.noneHeaderList = [ '/shop/detail', '/shopping', '/order/comfirmOrder' ]
    this.noneCenterHeaderList = [ '/center' ]
    // 以下页面——顶部工具栏颜色需灰色
    this.topToolGrey = [ '/shopping', '/order/comfirmOrder' ]
    return {
      search: '',
      noReadMessageCount: 0,
      LoginUser,
      ShopCar,
      logo,
      isCenter: false,
      path: '',
      loginFlag: LoginUser.isLogin() || false, // 是否登录，默认false
      userInfo: LoginUser.getUser(),
      goodsCategoryList: [],
      navList: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '禾众网商城',
          path: '/goods/list'
        },
        {
          name: '制种订单',
          path: '/purchase/list'
        },
        {
          name: '米制品种植订单',
          path: '/purchase/ricelist'
        },
        {
          name: '品牌商家',
          path: '/shop/list'
        }
      ],
      navLists: [
        {
          name: '首页',
          path: '/'
          // query:{
          //   path:0
          // }
        },
        {
          name: '我是买家',
          path: '/center/home',
          query: {
            path: 0
          }
        },
        {
          name: '我是卖家',
          path: '/center/homes',
          query: {
            path: 1
          }
        },
        {
          name: '资金管理',
          path: '/center/fund/memberfund',
          query: {
            path: 2
          }
        },
        {
          name: '账户管理',
          path: '/center/accountDetail',
          query: {
            path: 3
          }
        }
      ],
      navShow: false,
      platlink: []
    }
  },
  computed: {
    isBgGrey () {
      const i = this.topToolGrey.findIndex(v => {
        return this.path.includes(v)
      })
      return i > -1
    }
  },
  mounted () {
    this.path = this.$route.path
    // this.$store.commit('setRoleValue', '我是买家')
  },
  created () {
    this.loadGoodsCategoryList()
    if (LoginUser.isLogin()) {
      this.loadShopCar()
      this.readAllMessage()
    }
    if (sessionStorage.getItem('roleValue')) {
      this.$store.commit('setRoleValue', sessionStorage.getItem('roleValue'))
    }
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('roleValue', this.$store.getters.getRoleValue)
    })
  },
  methods: {
    goto (item) {
      if (item.isJump) {
        window.open(item.path, '_blank')
      } else {
        this.$router.push(item.path)
      }
    },
    showMessageListFn () {
      this.$bus.$emit('showMessageList', true)
    },
    goHome () {
      this.$router.push({
        path: '/'
      })
    },
    readAllMessage () {
      http.get('/message/countUnReadMessage', null).then(res => {
        this.noReadMessageCount = res
        this.$store.commit('setReadMessage', res)
      }).catch(error => {
        this.$message.error('获取未读信息异常:', error)
      })
    },
    // 屏蔽顶部
    setHeader () {
      const i = this.noneHeaderList.findIndex(v => {
        return this.path.includes(v)
      })
      return i > -1
    },
    // 屏蔽会员中心 搜索
    setCenterHeader () {
      const i = this.noneCenterHeaderList.findIndex(v => {
        return this.path.includes(v)
      })
      return i > -1
    },

    /**
     * 搜索商品
     */
    handleSearch () {
      if (this.$utils.isEmpty(this.search.trim())) {
        this.$message({
          type: 'warning',
          message: '请输入关键词'
        })
        this.search = ''
        return
      }
      console.debug('本次搜索的关键词为:', this.search)
      this.$router.push(`/goods/list?searchKey=${this.search}`)
    },
    handleNav (path) {
      return this.path === path
    },
    to: async function (path, query = {}) {
      if (path === '/center/homes') {
        const store = await shopApi.info()
        if (store !== null && store !== undefined && Reflect.has(store, 'sguid')) {
          this.$router.push({
            path, query
          })
        } else {
          if (this.$store.getters.getAuthtatus === -1) {
            // this.isAuthtatus = 99
          }
          if (this.$store.getters.getAuthtatus === 1) {
            this.$confirm('进入卖家中心需要开通店铺，是否现在就去开通？', '提示', {
              confirmButtonText: '开通店铺',
              cancelButtonText: '取消',
              type: 'warning',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              closeOnHashChange: false
            }).then(() => {
              this.$router.push('/center/store/apply')
            })
          }
          if (this.$store.getters.getAuthtatus !== -1 && this.$store.getters.getAuthtatus !== 1) {
            this.$confirm('您还没有进行认证，请先进行认证后进行开通卖家中心功能！', '提示', {
              confirmButtonText: '去认证',
              cancelButtonText: '取消',
              type: 'warning',
              closeOnClickModal: false,
              closeOnPressEscape: false,
              closeOnHashChange: false
            }).then(() => {
              this.$router.push('/center/info')
            })
          }
        }
      } else {
        this.$router.push({
          path, query
        })
      }
    },
    /**
     * 进入商品列表页面
     *
     * @param categoryId 分类编号
     */
    toGoodsListPage (categoryId, commodityId) {
      let url = `/goods/list?categoryId=${categoryId}`
      if (!this.$utils.isEmpty(commodityId)) {
        url += `&commodityId=${commodityId}`
      }
      this.$router.push(url)
    },

    /**
     * 属性进入商城
      * @param categoryId
     * @param pl
     * @param k
     * @param cl
     * @param v
     */
    toGoodsListAttributePage (categoryId, pl, k, cl, v) {
      let url = `/goods/list?categoryId=${categoryId}`
      if (!this.$utils.isEmpty(pl) &&
        !this.$utils.isEmpty(k) &&
        !this.$utils.isEmpty(cl) &&
        !this.$utils.isEmpty(v)) {
        url += '&pl=' + pl + '&k=' + k + '&cl=' + cl + '&v=' + v
      }
      console.info(url)
      this.$router.push(url)
    },

    // 退出
    logOut () {
      LoginUser.clear()
      sessionStorage.removeItem('isPerfectInfo')
      this.loginFlag = false
      this.$router.push('/login')
    },
    loadShopCar () {
      http.post('/front/cart/list', {}).then(data => {
        ShopCar.data = data
      }).catch(error => {
        console.error('查询购物车列表信息遇到异常:', error)
      })
    },
    async loadGoodsCategoryList () {
      let data
      try {
        data = await http.get('/goods/category/tree', {}, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
      } catch (e) {
        this.$myTip.error('查询商品分类信息失败:', e)
        return
      }

      data.forEach(p => {
        const links = []
        const dateMap = new Map()
        if (p.children) {
          p.children.forEach(c => {
            // 查询品名
            http.get('/goods/commodity/vo/list', { scategoryid: c.code }).then(list => {
              c.commodityList = list
              if (list && list.length > 0) {
                links.push(list[0])
              }
            })
          })

          // 获取首页显示的属性值
          if (p.resource.sjson) {
            const attrs = JSON.parse(p.resource.sjson)
            if (attrs) {
              attrs.forEach(a => {
                if (a.indexshow) {
                  let usedict = false
                  if (Reflect.has(a, 'dictType') && !this.$utils.isEmpty(a.dictType)) {
                    usedict = true
                  }
                  let defValues = a.defValues
                  if (usedict) {
                    defValues = this.$dictList(a.dictType)
                  }

                  dateMap.set(a.field, defValues)
                }
              })
            }
          }
        }
        p.links = links
        p.attrMap = dateMap
      })

      this.goodsCategoryList = data
      for (let i = 0; i < this.goodsCategoryList.length; i++) {
        this.goodsCategoryList[i].iconurl = require('@/assets/img/home/nav-icon' + i + '.png')
        // this.goodsCategoryList[i].iconurl = '/assets/img/home/nav-icon' + i + '.png''
      }
    },

    // 菜单显示和隐藏
    mouseenterMenu () {
      if (this.path === '/goods/category' || this.path === '/') {
        this.navShow = true
      } else {
        this.navShow = true
      }
    },
    mouseleaveMenu () {
      if (this.path === '/goods/category' || this.path === '/') {
        this.navShow = true
      } else {
        this.navShow = false
      }
    }
  },
  watch: {
    LoginUser: {
      handler () {
        if (LoginUser.isLogin()) {
          this.loadShopCar()
          this.readAllMessage()
        }
        this.loginFlag = LoginUser.isLogin()
      },
      deep: true
    },
    $route (to) {
      this.path = to.path
      if (to.path === '/center/home') {
        this.$store.commit('setRoleValue', '我是买家')
      } else if (to.path === '/center/homes') {
        this.$store.commit('setRoleValue', '我是卖家')
      } else if (to.path === '/center/fund/memberfund') {
        this.$store.commit('setRoleValue', '资金管理')
      } else if (to.path === '/center/accountDetail' || to.path === '/center/info') {
        this.$store.commit('setRoleValue', '账户管理')
      }
      console.log()
      if (this.path.indexOf('center') != -1) {
        this.isCenter = true
      } else {
        this.isCenter = false
      }
      this.navShow = (this.path === '/' || this.path === '/goods/category')
    }
  }
}
</script>
<style lang="scss">
$nav-height: 42px;
.header-bg {
  .header-module {
    width: 100%;
    height: 36px;
    line-height: 36px;
    background: #fcfcfc;
    border-bottom: 1px solid #f0f0f0;
    .w {
      &.center {
        max-width: 100%;padding: 0 15px;
      }
    }
    .header-left {
      float: left;
      font-size: 13px;
      color: #666;
      height: 40px;

      li {
        padding: 0 24px 0 10px;
        position: relative;

        a {
          margin-right: 8px;
          color: #666;
          font-size: 12px;
        }
        a.login-name {
          color: $color-primary-base;
        }
      }
    }

    .header-right {
      display: flex;
      float: right;
      align-items: center;

      li {
        float: left;
        padding: 0 2px;
        font-size: 13px;
        height: 30px;
        padding: 0 22px 0 10px;
        position: relative;
        font-size: 12px;
        color: #666;
        a{
          color: #666;
        }
      }

      .header-item {
        padding: 0 12px;
        i {
          padding: 2px 0;
          /*background: #17a77c;*/
          /*color: #fff;*/
          color: red;
          font-size: 12px;
          height: 14px;
          display: inline-block;
          vertical-align: middle;
          line-height: 10px;
          margin-left: 0;
          margin-bottom: 3px;
        }
      }

      .shu {
        width: 1px;
        height: 12px;
        margin-top: 9px;
        background: #ddd;
        padding: 0;
      }
    }
  }
  .top-tool-bg {
    /*background: #f6f6f6;*/
    padding: 12px 0;
  }
  .logo-box {
    margin: auto;
    align-items: center;
    img {
      width: inherit;
      height: 50px;
    }
    .header-tool-box {
      display: flex;
      align-items: center;
      b {
        font-weight: normal;
        margin-left: 20px;
      }
      span {
        display: inline-block;
      }
      a {
        position: relative;
        height: 22px;
        line-height: 22px;
        display: flex;
        align-items: center;
        display: block;
        margin-left: 20px;
      }
      .icon-text {
        padding-right: 30px;
        span {
          position: absolute;
          right: 0;
          top: -1px;
        }
      }
      .top-btn {
        width: 100px;
        height: 40px;
        text-align: center;
        border: 2px solid #f0f0f0;
        line-height: 32px;
        background: #fcfcfc;
        font-size: 12px;
      }
      .top-btn-login {
        background: #17a77c;
        border: 2px solid #17a77c;
        color: #fff;
      }
    }

    .search-box {
      /*flex: 60%;*/
      display: flex;
      justify-content: center;

      .s-input {
        float: left;
        height: 28px;
        line-height: 28px;
        padding: 4px;
        border-width: 2px 0 2px 2px;
        border-color: #17a77c;
        border-style: solid;
        font-size: 12px;
        font-family: 'microsoft yahei';
        width: 480px;
        outline: 0;
        color: rgb(153, 153, 153);
        box-sizing: content-box;
        padding-left: 20px;
        /*border-radius: 100px 0 0 100px;*/
      }

      .s-button {
        float: left;
        width: 68px;
        height: 40px;
        line-height: 38px;
        background: #17a77c;
        border: none;
        color: #fff;
        font-size: 16px;
        cursor: pointer;
        /*border-radius: 0 100px 100px 0;*/
      }
    }
  }

  .nav-bg {
    position: relative;
  }

  // 导航
  .nav-module {
    height: $nav-height;
    line-height: $nav-height;
    position: relative;
    background:#17a77c;
    // background: $color-primary-base;
    .nav-bg {
      position: relative;
      &.w {
        &.center{
          max-width: 100%;
        }
      }
    }
    .focus-a {
      width: 210px;
      height: $nav-height;
      position: relative;
      z-index: 999;
      line-height: 36px;

      .fouc-img1 {
        background: #17a77c;
        height: $nav-height;
        width: 210px;
        padding: 0 15px;
        &:hover {
          background: #17a77c;
        }
      }

      .fouc-font {
        font-size: 16px;
        height: $nav-height;
        line-height: $nav-height;
        text-align: center;
        a {
          color: #ffffff;
          height: $nav-height;
        }

        i {
          margin-right: 5px;
          color: #fff;
        }
      }
    }

    .focus-b {
      position: absolute;
      top: 0;
      left: 210px;
      .nav-box {
        height: $nav-height;
        li {
          float: left;
          color: #fff;
          a {
            /*padding: 0 25px;*/
            display: inline-block;
            width: 160px;
            text-align: center;
            height: $nav-height;
            line-height: $nav-height;
            color: #fff;
            position: relative;
            .icon{
              position: absolute;
              right: 10px;
              top: 0;
              height: 21px;
            }
          }
          a.active {
            background: $color-primary-depth;
            // background:#439274;
            font-size: 16px;
          }
        }
      }
    }

    .dd-inner {
      height: 408px;
      width: 210px;
      margin-left: 1px;
      margin-left: 0px;
      background: #fff;
      overflow-x: visible;
      box-sizing: border-box;
      a {
        cursor: pointer;
      }
      .dd-innerbg {
        overflow-y: scroll;
        overflow-x: visible;
        height: 404px;
        width: 210px;
        box-sizing: border-box;
        /*火狐隐藏滚动条*/
        scrollbar-width: none;
        width: -webkit-calc(210px + 17px);
        -ms-overflow-style: none;
      }

      .dd-inner-box {
        height: 404px;
        width: 210px;
        box-sizing: border-box;
      }
    }

    /*谷歌隐藏滚动条*/
    .dd-inner::-webkit-scrollbar {
      display: none;
    }

    .dd-innerbg::-webkit-scrollbar {
      display: none;
    }

    .dd-inner-box::-webkit-scrollbar {
      display: none;
    }

    .dd-inner .font-item {
      /*margin-bottom: 5px;*/
      /*background: #fff;*/
      border-left: 4px solid transparent;
      border-bottom: 1px solid #f0f0f0;
      border-right: 1px solid #f0f0f0;
      transition: all .3s ease-in-out;
      cursor: pointer;
      &:hover{
        padding-left: 16px;
        box-shadow: 0 0 10px 0 rgba(0,0,0,.08);
        border-left-color: $color-primary-base;;
        border-right-color: transparent;
      }
    }

    .dd-inner {
      .item {
        color: #fff;
        line-height: 36px;
        position: relative;
        padding: 10px 10px;
        h3 {
          font-size: 14px;
          font-weight: normal;
          height: 28px;
          line-height: 28px;
          a {
            color: #333;
            img{
              width: 28px;
              vertical-align: middle;
              margin-right: 5px;
            }
          }
        }
        p {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          a {
            color: #999;
            font-size: 12px;
          }
        }
      }
    }

    .dd-inner .item i {
      font: 400 14px/24px consolas;
      position: absolute;
      top: 12px;
      right: 30px;
      width: 4px;
      height: 14px;
      color: #999;
    }

    .dd-inner .item:hover {
      background: #fff;
      h3 a {
        color: $bg-color-hover;
      }
    }

    .bb {
      width: 228px;
      height: 2px;
      background: $color-primary-base;
      position: absolute;
      top: 49px;
    }

    .font-item1 {
      position: absolute;
      left: 210px;
      top: 41px;
      font-size: 14px;
      z-index: 100;
      width: 1000px;
      border: 1px solid #f0f0f0;
      border-left: none;
      background: #fff;
      display: none;
      height: 409px;
      overflow-y: scroll;
      box-sizing: border-box;
      /*box-shadow: 0 0 10px 0 rgba(0,0,0,.08);*/
    }

    .font-item1::-webkit-scrollbar {
      display: none;
    }

    .font-item1 .font-lefty {
      clear: both;
      float: left;
      padding: 6px 3px 56px 20px;
      position: relative;
      overflow-y: scroll;
      height: 467px;
      box-sizing: border-box;
      scrollbar-width: none;
      .fore1 {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        i {
          font: 400 9px/16px consolas;
          position: absolute;
          top: 19px;
          margin-left: 5px;
        }
      }
      .fore1 dt {
        min-width: 100px;
        padding: 5px 30px 0 0;
        float: left;
        position: relative;
        font-weight: bold;
        a{
          font-size: 13px;
        }
      }
      dd {
        width: 660px;
        float: left;
        margin-top: 5px;
        span {
          float: left;
          padding: 0 5px;
          margin: 5px 0;
          height: 16px;
          line-height: 16px;
          border-left: 1px solid #e0e0e0;
          position: relative;
          display: block;
          cursor: pointer;
          &:hover {
            color: $bg-color-hover;
          }
        }
        a {
          display: inline-block;
          float: left;
          padding: 0 10px;
          margin: 8px 0;
          height: 16px;
          line-height: 16px;
          border-left: 1px solid #e0e0e0;
          font-size: 12px;
          color: #999;
        }
      }
    }

    .font-item1 .font-lefty::-webkit-scrollbar {
      display: none;
    }

    .font-item1 .font-lefty dd span ul {
      /*display: none;*/
      position: absolute;
      left: 100%;
      top: 20px;
      background: #fff;
      width: 150px;
      z-index: 9999;
      box-shadow: 0 0 16px 0 hsla(0, 0%, 77%, 0.5);
      li {
        line-height: 2.2;
        border-bottom: 1px dashed #f5f5f5;
        padding: 3px 10px;
        a {
          color: #333;
          font-size: 12px;
        }
        a:hover {
          color: $bg-color-hover;
        }
      }
      li:first-child {
        border: none;
        font-size: 14px;
        margin-top: 10px;
      }
      li:first-child a {
        color: #4a8bed;
      }
    }

    .font-item:hover .font-item1 {
      display: block;
    }
  }
  .nav-module-else {
    background: #fff;
    border-bottom: 2px solid #17a77c;
    .focus-b .nav-box li a {
      color: #333;
      width: 130px;
    }
    .focus-b .nav-box li a.active {
      color: #17a77c;
      background: none;
      font-size: 16px;
    }
    .focus-a .fouc-img1 {
      background: #17a77c;
      .icon-arrow-down {
        opacity: 0;
      }
      .icon-menu {
        font-weight: bold;
        margin-right: 5px;
      }
    }
  }
}
  .yunscode{
    position: absolute;
    right: calc((100vw - 1200px)/2 + 50px);
    top: 40px;
    z-index: 10;
    img{
      display: block;
    }
    p{
      text-align: center;
      color: #666;
      font-size: 12px;
      margin-top: -5px;
    }
  }
</style>
