import {http} from 'security-view-deal';

/**
 * 资讯栏目API
 */
export default {
  treeUrl: '/cms/navigation/tree',
  tree(data) {
    return http.get(this.treeUrl, data);
  }
}
