<template>
  <div class="compare-container">
    <el-drawer
      :with-header="false"
      title=""
      :modal="false"
      :visible.sync="drawer"
      size="250px">
      <div class="compare-tag-wrapper" v-if="compareGoodsArr.length>0">
        <i @click="drawer = false" class="showIcon el-icon-arrow-right"></i>
        <p style="color:#000;font-size:16px;line-height: 66px;text-align: center;border-bottom:1px solid #aaa;">产品对比：</p>
        <div class="compare-tag">
          <el-tag
            v-for="(tag,i) in compareGoodsArr"
            :key="i"
            :disable-transitions="false"
            @close="closeTag(tag, i)"
            closable>
            {{tag.scommodityname}}
          </el-tag>
        </div>
        <div class="btnGroup">
          <el-button class="compareBtn" size="mini" type="primary" @click="goGoodsCompare">开始对比</el-button>
          <span style="margin: 0 30px;">最多5个</span>
          <a @click="cleanGoods">清空</a>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: 'Compare',
  props: {
    transitionName: {
      type: String,
      default: 'fade'
    }
  },
  data () {
    return {
      conpareNum: 0,
      drawer: false,
      compareGoodsArr: []
    }
  },
  created () {
    if (localStorage.getItem('COMPARE_GOODS')) {
      this.compareGoodsArr = JSON.parse(localStorage.getItem('COMPARE_GOODS')).concat()
      this.conpareNum = this.compareGoodsArr.length
    }
    this.$bus.$on('showCompareList', (status) => {
      this.drawer = status
    })
    this.$bus.$on('compareGoods', this.compareGoods)
  },
  mounted () {
    // if (localStorage.getItem('COMPARE_GOODS')) {
    //   this.showCompare = true
    // }
  },
  methods: {
    cleanGoods () {
      for (const i in this.compareGoodsArr) {
        this.$bus.$emit('deleteTags', this.compareGoodsArr[i])
        console.log('deleteTags')
      }
      this.compareGoodsArr = []
      this.conpareNum = 0
      localStorage.setItem('COMPARE_GOODS', JSON.stringify(this.compareGoodsArr))
      this.$store.commit('setCompareNum', this.conpareNum)
      this.$bus.$emit('showCompareIcon', false)
      this.$bus.$emit('showCompareList', false)
    },
    goGoodsCompare () {
      this.drawer = false
      this.$router.push('/goods/compare')
    },
    closeTag (tag, index) {
      this.compareGoodsArr.splice(index, 1)

      this.$bus.$emit('deleteTags', tag)

      localStorage.setItem('COMPARE_GOODS', JSON.stringify(this.compareGoodsArr))
      this.conpareNum--
      this.$store.commit('setCompareNum', this.conpareNum)
      if (this.conpareNum === 0) {
        this.$bus.$emit('showCompareIcon', false)
        this.$bus.$emit('showCompareList', false)
      }
    },
    compareGoods (good) {
      if (this.compareGoodsArr.length < 5) {
        this.compareGoodsArr.push(good)
        this.conpareNum++
      }
    }
  }
}
</script>

<style lang="scss">
  :focus {
    outline: 0;
  }
  .compare-container {
    .el-drawer__wrapper {
      visibility: hidden;
    }
    .el-drawer {
      visibility: initial;
    }
    .compare-tag-wrapper {
      // background: #666;
      width: 100%;
      height: 100%;
      position: relative;

      .el-tag {
        margin: 8px auto;
        border-radius: 0;
        font-size: 13px;
      }

      i.showIcon {
        font-size: 22px;
        color: #FF7700;
        position: absolute;
        top: 24px;
        left: 10px;
        cursor: pointer;

        &:hover {
          color: #FF7700;
        }
      }

      .btnGroup {
        position: absolute;
        bottom: 30px;
        right: 45px;
        color: #fff;
        width: 156px;

        button {
          margin-bottom: 10px;
        }

        span {
          font-size: 12px;
          display: inline-block;
          color: #ccc;
        }

        a {
          font-size: 13px;
          color: #d0111a;
          cursor: pointer;

          &:hover {
            color: red;
            text-decoration: underline;

          }
        }

        .compareBtn {
          padding: 15px 50px;
          font-size: 14px;
          border-radius: 0;
          background: #FF7700;
          border: none;

          span {
            color: #fff;
            font-size: 14px;
          }

          &:hover {
            background: #FF9900;
          }
        }
      }

      .compare-tag {
        padding: 20px;
        text-align: center;
      }
    }
  }
</style>
