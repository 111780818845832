// 卖方
export default [
  // 订单列表
  {
    path: '/center/seller/riceorders/list',
    component: () => import('@/views/center/seller/riceorders/list.vue')
  },
  // 添加
  {
    path: '/center/seller/riceorders/add',
    component: () => import('@/views/center/seller/riceorders/add.vue')
  },
  // 定向详情
  {
    path: '/center/seller/riceorders/detail/:id',
    component: () => import('@/views/center/seller/riceorders/detail.vue')
  }
]
