// 会员中心
import sellerRouter from './seller'
import buyerRouter from './buyer'
import fundRouter from './fund'
import memberRouter from './member'
import buyerreverseRouter from './buyerreverse'
import sellerreverseRouter from './sellerreverse'
import buyerseedordersRouter from '@/router/buyerseedorders'
import sellerseedordersRouter from '@/router/sellerseedorders'
import buyerriceordersRouter from '@/router/buyerriceorders'
import sellerriceordersRouter from '@/router/sellerriceorders'
export default [
  {
    path: '/center',
    component: () => import('@/views/center/index.vue'),
    children: [
      // 会员中心首页
      {
        path: '/center/home',
        component: () => import('@/views/center/member/home.vue')
      },
      {
        path: '/center/homes',
        component: () => import('@/views/center/member/homes.vue')
      },
      ...sellerRouter,
      ...buyerRouter,
      ...fundRouter,
      ...memberRouter,
      ...fundRouter,
      ...buyerreverseRouter,
      ...sellerreverseRouter,
      ...sellerseedordersRouter,
      ...sellerriceordersRouter,
      ...buyerseedordersRouter,
      ...buyerriceordersRouter,
      // 店铺
      {
        path: '/center/store/apply',
        component: () => import('@/views/center/store/apply.vue')
      },
      {
        path: '/center/store/info',
        component: () => import('@/views/center/store/info.vue')
      },

      // 签名回跳地址
      {
        path: '/sign/signok',
        component: () => import('@/views/sign/signok.vue')
      }
    ]
  }
].map(a => {
  a.meta = { authority: true }
  if (a.children) {
    a.children.forEach(arr => {
      arr.meta = { authority: true }
    })
  }
  return a
})
