<template>
  <div>
    <template v-if="preview">
      <div :style="{'background': setBg ? '#fff' : '#f8f8f8'}" class="p-t-15">
        <router-view />
      </div>
    </template>
    <template v-else>
      <!-- 顶部 -->
      <header-layout v-show="!setHeader() "></header-layout>
      <div :style="{'background': setBg ? '#fff' : '#f8f8f8'}" class="p-t-15">
        <router-view />
      </div>
      <backto-top></backto-top>
      <compare></compare>
      <compareList></compareList>
      <messageList></messageList>
      <footer-layout v-show="!setFooter() "></footer-layout>
    </template>
  </div>
</template>
<script>
import headerLayout from '@/views/layout/children/header.vue'
import footerLayout from '@/views/layout/children/footer.vue'
import backtoTop from '@/components/backtoTop.vue'
import compare from '@/components/compare.vue'
import compareList from '@/components/compare-list.vue'
import messageList from '@/components/messageList.vue'

export default {
  components: {
    headerLayout,
    footerLayout,
    backtoTop,
    compare,
    compareList,
    messageList
  },
  data () {
    return {
      path: '',
      preview: false
    }
  },
  created () {
    // 处理后台管理预览商品详情
    const t = this.$utils.urlParam('t')
    if (!this.$utils.isEmpty(t)) {
      const now = new Date().getTime()
      const limit = 1000 * 60 * 1
      const time = now - t
      this.preview = (time > 0 && time <= limit)
    }
  },
  computed: {
    setBg () {
      const noTopList = [ '/login', '/forgetPassword', '/register', '/shopping', '/goods/category', '/shop/detail','/sign/auth','/mobile/signIn','/mobile/signin','/mobile/newslist' ,'/mobile/newsDetail']
      const i = noTopList.findIndex(v => {
        return this.path.includes(v)
      })
      return !!((i > -1 || this.path == '/'))
    }
  },
  mounted () {
    this.path = this.$route.path
  },
  watch: {
    $route (to) {
      this.path = to.path
    }

  },
  methods: {
    // 屏蔽顶部
    setHeader () {
      const noHeaderList = [ '/register', '/login', '/forgetPassword' ,"/sign/auth",'/mobile/signIn', '/mobile/signin', '/mobile/signintwo','/mobile/newslist','/mobile/newsDetail']
      return noHeaderList.includes(this.path)
    },
    //屏蔽footer
    setFooter () {
      const noFooterList = [ "/sign/auth",'/mobile/signIn', '/mobile/signin', '/mobile/signintwo','/mobile/newslist','/mobile/newsDetail']
      return noFooterList.includes(this.path)
    }
  }
}
</script>
<style lang="scss">
// .sp-line {
//   margin-bottom: 20px;
//   width: 100%;
// }
</style>
