// 买方制种订单
export default [
  // 订单列表
  {
    path: '/center/buyer/riceorders/list',
    component: () => import('@/views/center/buyer/riceorders/list.vue')
  },
  // 订单详情
  {
    path: '/center/buyer/riceorders/detail/:id',
    component: () => import('@/views/center/buyer/riceorders/detail.vue')
  }
]
