export default {
  /**
   * 空值判断
   * @param v
   */
  isEmpty (v) {
    return undefined === v || v === null || v === '' || v.length === 0
  },
  /**
   * 空值替换
   * @param v
   * @param def
   * @returns {*}
   */
  val (v, def) {
    return this.isEmpty(v) ? def : v
  },
  /**
   * 获取浏览器网址中的参数
   * @param query
   * @param target
   * @returns {string|null}
   */
  urlParam (key, target = window.location.href) {
    var query = target.substring(1)
    var vars = query.split('&')
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=')
      if (pair[0] == key) { return pair[1] }
    }
    return null
  },
  /**
   * 修补文件网址
   * @param {String} url 文件网址
   */
  repairFileUrl (url) {
    if (this.isEmpty(url)) {
      return url
    }
    if (url.toLowerCase().startsWith('http://') || url.toLowerCase().startsWith('https://')) {
      return url
    }
    return `/api/member/download/private?fileName=${url}`
  },
  /**
   * 图片下载
   * @param imgSrc
   * @param name
   */
  downloadIamge (imgSrc, name = Math.random().toString().substr(2)) {
    var image = new Image()
    // 解决跨域 Canvas 污染问题
    image.setAttribute('crossOrigin', 'anonymous')
    image.onload = function () {
      var canvas = document.createElement('canvas')
      canvas.width = image.width
      canvas.height = image.height
      var context = canvas.getContext('2d')
      context.drawImage(image, 0, 0, image.width, image.height)
      var url = canvas.toDataURL('image/png') // 得到图片的base64编码数据

      var a = document.createElement('a') // 生成一个a元素
      var event = new MouseEvent('click') // 创建一个单击事件
      a.download = name // 设置图片名称
      a.href = url // 将生成的URL设置为a.href属性
      a.dispatchEvent(event) // 触发a的单击事件
    }
    image.src = imgSrc
  },
  isImage (fileName) {
    const index = fileName.lastIndexOf('.')
    if (index == -1) {
      return false
    }
    const suffix = fileName.substr(index + 1)
    const rightSuffix = '@jpg@jpeg@bmp@gif@png@'
    return rightSuffix.indexOf('@' + suffix + '@') != -1
  }

}
