import { http } from 'security-view-deal'

/**
 * 店铺API
 */
export default {
  pageUrl: '/member/membershop/paged',
  page (data) {
    return http.get(this.pageUrl, data)
  },
  commpageUrl: '/member/membershop/commpaged',
  commpage (data) {
    return http.get(this.commpageUrl, data)
  },
  infoUrl: '/member/membershop/shopInfo',
  info () {
    return http.post(this.infoUrl)
  },
  getOneByMemberidUrl: '/member/membershop/getOneByMemberid',
  getOneByMemberid () {
    return http.post(this.getOneByMemberidUrl)
  },
  applyInfoUrl: '/member/membershopapply/applyInfo',
  applyInfo () {
    return http.post(this.applyInfoUrl)
  },
  addApplyUrl: '/member/membershopapply/insertShopApply',
  addApply (data) {
    return http.post(this.addApplyUrl, data)
  },
  updateApplyUrl: '/member/membershopapply/updateShopApply',
  updateApply (data) {
    return http.post(this.updateApplyUrl, data)
  }
}
