// 资金管理中心
export default [
// 资金账户
  {
    path: '/center/fund/memberfund',
    component: () => import('@/views/center/fund/lakala/memberfund.vue')
  },
  // 银行回单记录
  {
    path: '/center/fund/bankreceiptlist',
    component: () => import('@/views/center/fund/bankreceiptlist.vue')
  },
  // 会员充值
  {
    path: '/center/fund/infund',
    component: () => import('@/views/center/fund/infund.vue')
  },
  // 会员提现
  {
    path: '/center/fund/outfund',
    component: () => import('@/views/center/fund/outfund.vue')
  },
  // 账户开通
  {
    path: '/center/fund/openfund',
    component: () => import('@/views/center/fund/lakala/openfund.vue')
  }
]
